import _ from 'lodash';
import { titleize } from 'inflected';
import { isObject } from './utils';
import type { IConfigObj } from './types';
import type { DatabaseColumnDescriptor, DatabaseDescriptors } from './api/api-query-service';
import { IConfigFlags } from './config-flags';

export interface IPropertyDefinitionSort {
    field: string;
    order: 1 | -1;
}

export type IPropertyCategory = { id: string; label: string };

// TODO: rename to Property
export interface IPropertyDefinition {
    id: string;
    table: string;
    column: string;
    label: string;
    plural: string;
    filterable: boolean;
    hierarchical: boolean;
    group?: string;
    category: IPropertyCategory;
    pinned: boolean;

    /**
     * @deprecate This is used by the metrics page... unclear where else...
     * */
    type?: string;

    /**
     * To review:
     * Used by transaction_items.timestamp__hour
     **/
    sort?: IPropertyDefinitionSort;
}

export type IHierarchyItem = IPropertyDefinition;

// Most of the time, we only have one standard hierarchy...
export type IHierarchy = IPropertyDefinition[];

export function getPropertyDefinitions(
    userConfig: IConfigObj,
    orgConfig: IConfigObj,
    descriptors?: DatabaseDescriptors,
) {
    let { stores, items } = getStandardHierarchiesFromConfig(userConfig, orgConfig);
    if (descriptors?.stores) stores ??= getHierarchyFromDescriptors(descriptors.stores, 'store');
    if (descriptors?.items) items ??= getHierarchyFromDescriptors(descriptors.items, 'item');
    stores = stores ?? [];
    items = items ?? [];

    const all = [...stores, ...items];
    const groupByPropertyIds = getGroupByPropertyIdsFromConfig(userConfig, orgConfig);
    let groupBy = selectPropertiesById(all, groupByPropertyIds);
    if (groupBy.length === 0) groupBy = all;

    return {
        all,
        groupBy: groupBy,
        segments: {
            stores: stores.filter(isFilterableProperty),
            items: items.filter(isFilterableProperty),
        },
    };
}

export const CATEGORIES = {
    locations: {
        id: 'locations',
        label: titleize('locations'),
    },
    items: {
        id: 'items',
        label: titleize('items'),
    },
    transactions: {
        id: 'transactions',
        label: titleize('transactions'),
    },
    marketing: {
        id: 'marketing',
        label: titleize('marketing'),
    },
    calendar: {
        id: 'calendar',
        label: titleize('calendar'),
    },
    customers: {
        id: 'customers',
        label: titleize('customers'),
    },
    shipping_address: {
        id: 'shipping-address',
        label: 'Shipping Address',
    },
    billing_address: {
        id: 'billing-address',
        label: 'Billing Address',
    },
    data: {
        id: 'data',
        label: 'Data',
    },
} as const;

// prettier-ignore
export const CATEGORIES_BY_PREFIXES: Record<string, IPropertyCategory> = {
    'stores'                       : CATEGORIES.locations,
    'stores.source'                : CATEGORIES.data,
    'warehouses'                   : CATEGORIES.locations,
    'order_items'                  : CATEGORIES.transactions,
    'transaction_items'            : CATEGORIES.transactions,
    'transactions'                 : CATEGORIES.transactions,
    'demand_items'                 : CATEGORIES.transactions,
    'demand'                       : CATEGORIES.transactions,
    'joined_transaction_items'     : CATEGORIES.transactions,
    'joined_demand_items'          : CATEGORIES.transactions,
    'shipping_items'               : CATEGORIES.transactions,
    'tax_items'                    : CATEGORIES.transactions,
    'demand_tax_items'             : CATEGORIES.transactions,
    'gift_card_items'              : CATEGORIES.transactions,
    'sales__'                      : CATEGORIES.transactions,
    'item_timeseries.order'        : CATEGORIES.transactions,
    'item_timeseries.tax_'         : CATEGORIES.transactions,
    'item_timeseries.payment_'     : CATEGORIES.transactions,
    'item_timeseries.shipping_'    : CATEGORIES.shipping_address,
    'item_timeseries.billing_'     : CATEGORIES.billing_address,
    'items'                        : CATEGORIES.items,
    'item_timeseries'              : CATEGORIES.items,
    'campaigns'                    : CATEGORIES.marketing,
    'acquisitions'                 : CATEGORIES.marketing,
    'calendar_periods'             : CATEGORIES.calendar,
    'calendar'                     : CATEGORIES.calendar,
    'transactions.timestamp__hour' : CATEGORIES.calendar,
    'customers'                    : CATEGORIES.customers,
    'item_timeseries.customer_'    : CATEGORIES.customers,
    'order_items.customer_'        : CATEGORIES.customers,
};

export function isFilterableProperty(property: IPropertyDefinition) {
    if (['transactions', 'calendar_periods'].includes(property.table)) return false;
    if (['stores.company', 'stores.aggregate'].includes(property.id)) return false;
    if (!property.filterable) return false;
    return true;
}

function normalizePropertyCategory({
    category,
    ...property
}: {
    id: string;
    table: string;
    column: string;
    category: unknown;
}): IPropertyCategory {
    if (typeof category === 'string') return { id: category, label: category };

    if (isObject(category) && typeof category.id === 'string') {
        const id = category.id;
        const label = typeof category.label === 'string' ? category.label : titleize(id);
        return { id, label };
    }

    let match: IPropertyCategory | undefined;

    // Exact match on table / column
    match = CATEGORIES_BY_PREFIXES[property.id];
    if (match) return _.cloneDeep(match);

    // Exact match on table, startsWith on column
    match = _.orderBy(
        Object.entries(CATEGORIES_BY_PREFIXES).filter(
            ([p]) =>
                p.includes('.') && p.split('.')[0] === property.table && property.column.startsWith(p.split('.')[1]),
        ),
        x => x[0].length,
        'desc',
    ).pop()?.[1];
    if (match) return _.cloneDeep(match);

    // Exact match on table
    match = CATEGORIES_BY_PREFIXES[property.table];
    if (match) return _.cloneDeep(match);

    // Starts with match on column
    match = _.orderBy(
        Object.entries(CATEGORIES_BY_PREFIXES).filter(([p]) => Boolean(property.id.startsWith(p))),
        x => x[0].length,
        'desc',
    ).pop()?.[1];
    if (match) return _.cloneDeep(match);

    return {
        id: property.table,
        label: titleize(property.table),
    };
}

function normalizePropertySort({ id, sort }: { id: string; sort: unknown }): IPropertyDefinitionSort | undefined {
    if (!isObject(sort)) return;
    const order = sort.order === 1 || sort.order === -1 ? sort.order : undefined;
    if (typeof order !== 'number') return;
    const field = typeof sort.field === 'string' ? sort.field : id;
    return { field, order };
}

function normalizePinned({ pinned, id }: { pinned: unknown; id: string }): boolean {
    if (typeof pinned === 'boolean') return pinned;
    return ['stores.company', 'stores.aggregate'].includes(id);
}

export function normalizePropertyDefinition(
    property: Partial<Omit<IPropertyDefinition, 'id'>> & { id: string },
): Omit<IPropertyDefinition, 'group'>;

export function normalizePropertyDefinition(
    property: Partial<Omit<IPropertyDefinition, 'table' | 'column'>> & { table: string; column: string },
): Omit<IPropertyDefinition, 'group'>;

export function normalizePropertyDefinition(property: unknown): Omit<IPropertyDefinition, 'group'>;
export function normalizePropertyDefinition(property: unknown): Omit<IPropertyDefinition, 'group'> {
    if (!isObject(property)) throw new Error('Invalid property, is null');

    // Normalize ID
    const { id, table, column } = parsePropertyId(property);
    const label = typeof property.label === 'string' ? property.label : titleize(column);
    const plural = typeof property.plural === 'string' ? property.plural : label;
    const filterable = typeof property.filterable === 'boolean' ? property.filterable : true;
    const hierarchical = typeof property.hierarchical === 'boolean' ? property.hierarchical : true;

    const sort = isObject(property.sort) ? normalizePropertySort({ id, sort: property.sort }) : undefined;
    const category = normalizePropertyCategory({ id, table, column, category: property.category });
    const pinned = normalizePinned({ pinned: property.pinned, id });

    return { id, table, column, category, label, plural, filterable, pinned, hierarchical, ...(sort ? { sort } : {}) };
}

const PROPERTY_REGEXP = /^([a-z0-9_]+)\.([a-z0-9_]+)$/i;
function parsePropertyId(property: Record<string, unknown>): { id: string; table: string; column: string } {
    const value = (() => {
        if (typeof property.id === 'string') {
            return property.id;
        }
        if (typeof property.table === 'string' && typeof property.column === 'string') {
            return `${property.table}.${property.column}`;
        }
        console.error('Invalid hierarchy property:', property);
        throw new Error(`Hierarchy property is misconfigured, must have an 'id', or 'table' and 'column'.`);
    })();
    const [table, column] = value.match(PROPERTY_REGEXP)?.slice(1) ?? [];
    if (!table || !column) {
        throw new Error(`Invalid property '${value}'; must be formatted like <table>.<column>`);
    }
    return { id: value, table, column };
}

function normalizeHierarchy(properties: IHierarchy, group?: string): IHierarchy {
    return properties.flatMap(value => {
        try {
            const def = normalizePropertyDefinition(value);
            return { ...(group ? { group } : {}), ...def };
        } catch (error) {
            console.error(error);
            return [];
        }
    });
}

function getHierarchyFromDescriptors(descriptors: DatabaseColumnDescriptor[], group?: string): IHierarchy {
    try {
        return descriptors.map(descriptor => {
            const table = descriptor.table;
            const column = descriptor.name;
            const def = normalizePropertyDefinition({ table, column });
            return { ...(group ? { group } : {}), ...def };
        });
    } catch (error) {
        console.error(error);
        throw new Error('Could not convert descriptor to property.');
    }
}

function getStandardHierarchiesFromConfig(
    userConfig: IConfigObj,
    orgConfig: IConfigObj,
): Record<string, null | IHierarchy> {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const stores = userConfig?.stores?.hierarchy || orgConfig?.stores?.hierarchy2 || orgConfig?.stores?.hierarchy;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const items = userConfig?.items?.hierarchy || orgConfig?.items?.hierarchy2 || orgConfig?.items?.hierarchy;
    return {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
        stores: stores ? normalizeHierarchy(stores, 'store') : null,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
        items: items ? normalizeHierarchy(items, 'items') : null,
    };
}

const logPropertyNotFoundWarning = _.memoize((propertyId: string) => {
    console.warn('[config-hierarchy]', 'Property not found in hierarchy:', propertyId);
});
function selectPropertiesById<T extends { id: string }>(
    properties: T[],
    propertyIds: undefined | null | string[],
): T[] {
    if (!Array.isArray(propertyIds)) return [];
    propertyIds = propertyIds.filter(x => typeof x === 'string');
    propertyIds = _.uniq(propertyIds);
    const propertiesById = _.keyBy(properties, 'id');
    return propertyIds.flatMap(propertyId => {
        const property = propertiesById[propertyId];
        if (property) return [{ ...property }];
        logPropertyNotFoundWarning(propertyId);
        return [];
    });
}

function getGroupByPropertyIdsFromConfig(userConfig: IConfigObj, orgConfig: IConfigObj): string[] {
    let config: string[];
    config = userConfig.views?.metrics?.properties ?? orgConfig.views?.metrics?.properties ?? [];
    if (!Array.isArray(config)) {
        throw new Error('Invalid view.metrics.properties, must be an array');
    }
    config = config.filter(x => x.length > 0);
    if (!config.every(x => typeof x === 'string')) {
        throw new Error('Invalid view.metrics.properties, must be an array of strings');
    }
    return config;
}

export async function fetchHourProperty(flags?: IConfigFlags) {
    if (!flags?.showHourDimension) return null;
    const property = 'transactions.timestamp__hour';
    return normalizePropertyDefinition({
        id: property,
        label: 'Hour',
        sort: { field: property, order: 1 },
    });
}

export function fetchCalendarProperties(descriptors: DatabaseDescriptors) {
    if (!descriptors.calendar) return null;
    const hasQuarter = !!descriptors.calendar.find(x => x.name === 'quarter_label');
    const hasSeason = !!descriptors.calendar.find(x => x.name === 'season_label');
    return _.compact([
        {
            id: 'calendar_periods.period_label',
            label: 'Calendar Period',
            plural: 'Calendar Periods',
        },
        {
            id: 'calendar.year',
            label: 'Year',
            plural: 'Years',
        },
        // Disabled until all orgs have it
        // {
        //     id: 'calendar.year_label',
        //     label: 'Year Label',
        //     plural: 'Years',
        // },
        hasSeason && {
            id: 'calendar.season_label',
            label: 'Season (Calendar)',
            plural: 'Seasons (Calendar)',
        },
        hasQuarter && {
            id: 'calendar.quarter_label',
            label: 'Quarter',
            plural: 'Quarters',
        },
        {
            id: 'calendar.month_label',
            label: 'Month Label',
            plural: 'Month Labels',
        },
        {
            id: 'calendar.week',
            label: 'Week',
            plural: 'Weeks',
        },
        // {
        //     id: 'calendar.week_label',
        //     label: 'Week (DNU)',
        //     plural: 'Weeks (DNU)',
        // },
        {
            id: 'calendar.timestamp',
            label: 'Date',
            plural: 'Dates',
        },
    ]).map(x => normalizePropertyDefinition(x));
}
