import _ from 'lodash';
import * as Utils from '../../lib/utils';
import type { IMetricsPageTabsViewModel } from './metrics-tabs-view-model';
import { MetricsFunnel } from './metrics-funnel';

export type IMetricsPageTabViewModelParams = {
    id?: string;
    name?: string;
} & IMetricsFunnelState;

export type IMetricsFunnelState = Record<string, unknown>;
export type IMetricsPageTabViewModel = InstanceType<typeof MetricsPageTabViewModel>;

export class MetricsPageTabViewModel {
    readonly id: string;
    readonly name: string;
    readonly parent: IMetricsPageTabsViewModel;
    protected state: null | Record<string, unknown>;
    protected instance: null | MetricsFunnel = null;
    protected invalid = false;

    constructor(parent: IMetricsPageTabsViewModel, { id, name, ...state }: IMetricsPageTabViewModelParams = {}) {
        this.id = id ?? Utils.uuid();
        this.name = name ?? 'New View';
        this.parent = parent;
        this.state = _.cloneDeep(state);
        this.instance = null;
    }

    private createInstance() {
        return MetricsFunnel.deserialize({
            properties: this.parent.properties,
            metrics: this.parent.metrics,
            experiments: this.parent.experiments,
            actions: { save: () => void this.parent.saveState() },
            state: this.state ?? {},
        });
    }

    get funnel() {
        if (!this.instance && !this.invalid) {
            try {
                this.instance = this.createInstance();
            } catch (error) {
                this.invalid = true;
                throw error;
            }
        }
        return this.instance;
    }

    refresh() {
        if (!this.instance) return;
        this.state = _.cloneDeep(this.instance.serialize());
        this.instance = this.createInstance();
    }

    reset() {
        return this.parent.resetTab(this);
    }
}
