import { AuthServiceFactory } from '@42technologies/client-lib-auth';
import { AppConfigService } from './config-app';
import { UserOrganizationStorage } from './storage-user-organization';

const AuthService = new AuthServiceFactory({ window: window, config: AppConfigService.getAuth() });
export { AuthService };

export async function logout(): Promise<never> {
    const auth = await AuthService.get();
    return await auth.logout();
}

export async function getUser() {
    const api = await AuthService.get();
    const user = api.getUser();

    if (user) return await user;

    console.warn('No user found.');
    return await logout();
}

export async function getOrganization(): Promise<string> {
    const user = await getUser();
    let organization = UserOrganizationStorage.get();
    if (organization && user.organizations.includes(organization)) {
        return organization;
    }
    if (!organization) {
        console.warn('No saved organization found.');
    } else {
        console.warn(`Stored organization '${organization}' is not part of user's available orgs.`);
        console.warn('Available organizations:', user.organizations);
    }
    organization = user.organizations[0] ?? null;
    if (organization) void UserOrganizationStorage.set(organization);
    organization = UserOrganizationStorage.get();
    // this shouldn't happen in practice
    if (!organization) throw new Error('No organization found.');
    return organization;
}

const URLS_TO_CLEAN = ['/reporting/reports', '/reporting/schedules'];

function cleanURL(urlToVerify: string): URL {
    const urlToClean = URLS_TO_CLEAN.find(urlToClean => urlToVerify.includes(urlToClean));
    const url = new URL(urlToVerify);
    if (!urlToClean) return url;
    url.pathname = `${url.pathname.split(urlToClean)[0] ?? ''}${urlToClean}`;
    return url;
}

export function setOrganization(organization: string): Promise<never> {
    if (!organization) return Promise.reject(new Error(`Missing required ${organization} argument.`));
    void UserOrganizationStorage.set(organization);
    window.location.replace(cleanURL(window.location.href));
    return new Promise(() => void 0);
}

export async function isAdmin(): Promise<boolean> {
    const user = await getUser();
    return user && (user.isOrgAdmin() || user.isRootAdmin());
}

export function isAllsaintsOrganizationId(organizationId: string) {
    return ['allsaints_dev', 'allsaints-new', 'allsaints'].includes(organizationId);
}
